
import { createDisplayOption } from "core/crud/modelDisplay";
import { createField, selectOption } from "core/crud/modelField";
import { WebinarContact } from "share/entities/webinar-contact.entity";


export const WebinarContactView = createDisplayOption({
  modelTitle:'',
  modelContructor: WebinarContact,
  maximum:2,
  fields:[
    createField<WebinarContact>({id:'new_email', label:'Email'}),
    createField<WebinarContact>({id:'new_firstname', label:'First Name'}),
    createField<WebinarContact>({id:'new_lastname', label:'Last Name'}),
    createField<WebinarContact>({id:'new_title', label:'Title'}),
    createField<WebinarContact>({id:'new_admin_license', label:'Have Administrator License', inputType:'select', selectOptions:[new selectOption('yes','Yes'), new selectOption('no','No')]}),
    createField<WebinarContact>({id:'new_name_on_nab', label:'Name on NAB'}),
    createField<WebinarContact>({id:'new_nab_id_number', label:'NAB ID #'}),
    createField<WebinarContact>({id:'new_cap_cpe_required', label:'CPA CPE required', inputType:'select', selectOptions:[new selectOption('yes','Yes'), new selectOption('no','No')]}),
  ]
})