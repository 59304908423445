import { MyBaseEntity } from "./myBaseEntity";
import { Entity, Column,ManyToOne, OneToMany } from "decorators/entitydecorators";
import { WebinarContact } from "./webinar-contact.entity";
import {IsArray, IsNotEmpty, ValidateNested, ArrayMinSize, ArrayMaxSize, Matches, isNotEmpty } from "class-validator";
import { Type } from 'class-transformer';
import { webinar_facility_schema, webinar_schema, webinar_contact_schema } from "./entity-schema-names";
import { IsCustomFormat } from "../decorators/isCustomFormat.decorator";

@Entity(webinar_facility_schema ,'Webinar Facility','Webinar Facilities')
export class WebinarFacility extends MyBaseEntity{
    @IsNotEmpty()
    @Column('Have Administrator License')
    new_admin_license =''
    @IsNotEmpty()
    @Column('What License do you have')
    new_type_license =''

    @Matches(/^(?!\s*$).+/, { message: 'SNF/Organization Name should not be empty' })
    @Column('Facility Name',{primary:true,length:100})
    new_name =''

    @IsNotEmpty()
    @Column('Name on NAB')
    new_name_on_nab= ''

    @IsNotEmpty()
    @IsCustomFormat(/^R\d{7}$/, { message: 'Invalid NAB ID # format' })
    @Column('NAB ID #')
    new_nab_id_number= ''

    @IsNotEmpty()
    @Column('CPA CPE required')
    new_cap_cpe_required = ''

    @Matches(/^(?!\s*$).+/, { message: 'Address line 1 should not be empty' })
    @Column('Street Line')
    new_street_line=''

    @Column('Street Line2')
    new_street_line2=''

    @Matches(/^(?!\s*$).+/, { message: 'City should not be empty' })
    @Column('City')
    new_city=''

    @Matches(/^(?!\s*$).+/, { message: 'State/Province/Region should not be empty' })
    @Column('State')
    new_state=''

    @Matches(/^(?!\s*$).+/, { message: 'Zip / Postal code should not be empty' })
    @Column('zip')
    new_zip =''

    @Matches(/^(?!\s*$).+/, { message: 'SNF/Organization Telephone should not be empty' })
    @Column('Telephone')
    new_telephone=''

    @Column('Extension')
    new_extension=''

    @Column('Subscription Date')
    new_subscription_date?:Date

    @Column('Paid')
    new_paid=''

    @Column('PayPal Order ID')
    new_paypal_orderid=''

    @Column('PayPal Account')
    new_paypal_email=''

    @IsNotEmpty()
    @Column('Subscription Type')
    new_type = ''

    @ManyToOne('account','Related Account')
    new_account? :any = undefined
    
    @OneToMany(webinar_contact_schema)
    @ValidateNested({ each: true })
    @Type(() => WebinarContact)
    contacts: WebinarContact[] 
}

export type registerType = 'onetime' | 'yearly' | 'redeem' 
export const getFeeByType = (type: registerType) => type === 'onetime'?'95.00' : type==='yearly'?'750.00' :'Complimentary'