import "reflect-metadata"

export type DynamicsFieldType = 'String' |  'DateTime'
export class AttributeMeta {
  primary:Boolean = false
  label:string =''
  name:string = ''
  type:DynamicsFieldType = 'String'
  length:number = 200
}
export interface ManyToOneMeta{
  ReferencingAttributeLabel:string
  ReferencingAttribute:string
  ReferencedEntity:string
}
export interface OneToManMeta{
  arrayName:string
  childSchemaName:string
}
export interface EntityMeta{
  DisplayName:string
  SchemaName:string
  CollectionName:string
  DisplayCollectionName:string
  manyToOnes : ManyToOneMeta[]
  oneToManys: OneToManMeta[]
  attributes : AttributeMeta[]
  constructor : any
}
export const OneToMany = (
  childSchemaName:string
)  => (target:Object, arrayName:string)=>{
  oneToManys.push({
    childSchemaName,
    arrayName
  })
}
export const ManyToOne = (
  ReferencedEntity:string,
  ReferencingAttributeLabel:string,
)  => (target:Object, name:string)=>{
  manyToOnes.push({
    ReferencedEntity,
    ReferencingAttribute:name,
    ReferencingAttributeLabel
  })
}
export const Entity = (SchemaName: string,DisplayName:string, DisplayCollectionName:string) => (target: any) => {
  const entityOption:EntityMeta = {
    SchemaName,DisplayName, DisplayCollectionName,
    CollectionName: getCollectionName(SchemaName),
    oneToManys :[...oneToManys],
    manyToOnes:[...manyToOnes],
    attributes:[...attrs],
    constructor: target
  }

  target.entityOption = entityOption
  repoMap.set(getCollectionName(SchemaName), entityOption)
  manyToOnes = []
  attrs = []
  oneToManys = []
}
export function Column(label:string,patial: Partial<AttributeMeta> = {}){
  return (target:any,name:string) =>{
      if (!patial.type)
      {
        const metaType =  Reflect.getMetadata("design:type", target, name)?.name
        patial.type = 
          metaType === 'Date' ? 'DateTime' : 
          metaType === 'Object'?'String' : 
          metaType
      }
      attrs.push({
        ...new AttributeMeta(),
        ...patial,
        label,
        name,
      })
  }
}

const getCollectionName = (name:string) => name.endsWith('y') ? name.substring(0, name.length - 1) + "ies" 
        : name.endsWith('s') ? name +'es'
        : name + "s"

export const getEntityMeta =(type:string | (new ()=>Object)) =>{
  if (typeof type === 'string'){
    return repoMap.get(type) ?? repoMap.get( getCollectionName(type))
  }else {
    return (type as any).entityOption as EntityMeta
  }
}

const repoMap = new Map<string, EntityMeta>() 
let manyToOnes:ManyToOneMeta[] = []
let oneToManys: OneToManMeta[] = []
let attrs : AttributeMeta[] = []

