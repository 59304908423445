import React, { useState, useRef, Fragment } from 'react'
import { CssBaseline, AppBar, Toolbar, Link, Typography, Paper, Grid, Button, ListItem, ListItemText, CircularProgress, makeStyles } from '@material-ui/core'
import { Webinar } from 'share/entities/webinar.entity';
import { MyTextField } from 'components/form/myinput';
import { useForm } from 'react-hook-form';
import { useQuery } from 'components/hooks/useQuery';
import { useFetchRequest } from 'core/fetch/useFetchRequest';
import { WebinarFacility } from 'share/entities/webinar-facility.entity';
import { WebinarContact } from 'share/entities/webinar-contact.entity';
import { Alert } from '@material-ui/lab';
import { validateEntity } from 'core/utilities/formUtility';
import { WebinarAttendee } from 'share/entities/webinar-attendee.entity';
import { dateToLongString, myDateToString, dateToLongStringTime } from 'share/utilities/myDate';

const useStyles = makeStyles((theme) => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
            width: 800,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        width: 800,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },

    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    appBar: {
        position: 'relative'
    },
    listItem: {
        padding: theme.spacing(1, 0),
    },
    total: {
        fontWeight: 700,
    },
    title: {
        marginTop: theme.spacing(2),
    },
}));

export function Register() {
    //isolated hook
    const [token] = useQuery(['token'])
    const classes = useStyles();
    const { register, handleSubmit } = useForm()
    const formRef = useRef<HTMLFormElement>(null);
    //local state
    const [errors, setErrors] = useState(new Map<string, any>())

    //other
    const [attendeeState, submitAttendee] = useFetchRequest<unknown>(false, 'POST', `attendee/${token}`)
    const [tokenState] = useFetchRequest<{ facility: WebinarFacility, contact: WebinarAttendee, webinar: Webinar }>(true, 'GET', `attendee/${token}`)
    const { facility, contact, webinar } = tokenState?.result ?? {}
    const props = { register, errors, item: contact }

    function triggerSubmit() {
        formRef?.current?.dispatchEvent(new Event("submit"))
    }
    async function onSubmit(data: any) {
        const { errs, item } = await validateEntity(WebinarAttendee, data)
        setErrors(errs)
        if (errs.size === 0) {
            if(item.new_email == contact?.new_email)
                submitAttendee(contact)
            else
                submitAttendee(item)
        }
    }

    function MyListItem({ title, value }: { title: string, value: string | undefined }) {
        return <ListItem className={classes.listItem}>
            <ListItemText primary={title} />
            <Typography variant="subtitle1" className={classes.total}>
                {value}
            </Typography>
        </ListItem>
    }

    return <React.Fragment>
        <CssBaseline />
        <AppBar color="default" className={classes.appBar}>
            <Toolbar>
                <img src="https://zimmet.microsoftcrmportals.com/zimmet.jpg" style={{ alignSelf: "middle" }} />
                <Typography component="h1" variant="h5" align="center">Zimmet Healthcare Services Group, LLC </Typography>
            </Toolbar>
        </AppBar>
        <React.Fragment>
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    {tokenState === undefined || tokenState.status === 'Loading' ? <CircularProgress size={68} />
                        : tokenState.status === 'Fail' ? <Alert severity='error'>{tokenState.error}</Alert>
                            : attendeeState?.status === 'Succeed'
                                ? <div>
                                    <Typography component="h1" variant="h4" align="center"> Thank you for registering for the upcoming webinar: </Typography>
                                    <Typography component="h2" variant="h6" align="left">{webinar?.new_name} </Typography>
                                    <Typography component="h3" variant="h6" align="left">Date: {new Date(webinar?.new_start ?? new Date()).toLocaleString()}</Typography>
                                    <Typography component="h1" variant="h5" align="left">You will receive a separate e-mail with the link to login to the webinar.  </Typography>
                                </div>
                                : <Fragment>
                                    {attendeeState?.status === 'Loading' && <CircularProgress size={68} />}
                                    {attendeeState?.status === 'Fail' && <Alert severity='error'>{attendeeState?.error}</Alert>}
                                    <Typography component="h1" variant="h4" align="center"> Zimmet Webinar Registration </Typography>
                                    <Typography component="h1" variant="subtitle1" align="left">{webinar?.new_name}</Typography>
                                    <Typography component="h1" variant="subtitle2" align="left">{dateToLongStringTime(webinar?.new_start ?? new Date())} EST</Typography>
                                    <Typography component="h1" variant="subtitle1" align="left">{facility?.new_name}</Typography>
                                    <br/>
                                    <React.Fragment>
                                        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={12}>
                                                    <MyTextField name="new_firstname" label="First name*" {...props} />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <MyTextField name="new_lastname" label="Last name*" {...props} />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <MyTextField name="new_email" label="Email*" {...props} />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <MyTextField name="new_title" label="Title*" {...props} />
                                                </Grid>
                                            </Grid>
                                        </form>
                                        <div className={classes.buttons}>
                                            <Button variant="contained" color="primary" onClick={triggerSubmit} className={classes.button} >Submit </Button>
                                        </div>
                                    </React.Fragment>
                                </Fragment>
                    }
                </Paper>
            </main>
            <footer color="default"  >
                <Toolbar className={"space-between"}>
                    <Typography component="p" color="inherit" noWrap>
                        <a style={{textDecoration:"none", color:"#cccccc",fontSize:"12px"}} href="https://www.hartogjacobs.com/">© Hartog Jacobs LLC </a>
                    </Typography>
                    <img width='100' src={'HJLogo.png'} />
                </Toolbar>
            </footer>
        </React.Fragment>

    </React.Fragment>

}