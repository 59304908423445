import { ValidationError } from "class-validator/types/validation/ValidationError"
import { validate } from "class-validator"
import { plainToClass } from "class-transformer"
import { mergeObject } from "share/utilities/arrayUtility"

export async function validateEntity<T extends Object>(type: new()=>T ,
  source: any, fields: string[] | undefined= undefined) {

  const item = plainToClass(type,source)
  let errs :ValidationError[] = await validate(item)
  if (fields){
    errs = errs.filter(x=>fields.includes(x.property))
  }
  return {errs:mergeError(errs), item}
 }

function mergeError(validateErrors:ValidationError[]){
  const errorMap = new Map<string,any>()
  for (const vr of validateErrors.filter (x=>x.constraints))
  {
    errorMap.set(vr.property, mergeObject(vr.constraints))
  }
  for (const vr of validateErrors.filter(x=>x.children.length > 0)){
    const subError = mergeError(vr.children)
    errorMap.set(vr.property, subError)
  }
  return errorMap
}

