import { registerDecorator, ValidationOptions, ValidationArguments, ValidatorConstraint } from 'class-validator';

export function IsCustomFormat(format: RegExp, validationOptions?: ValidationOptions) {
  return (object: any, propertyName: string) => {
    registerDecorator({
      name: 'isCustomFormat',
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [format],
      validator: CustomFormatConstraint,
    });
  };
}

@ValidatorConstraint({ name: 'CustomFormat', async: false })
export class CustomFormatConstraint {
  validate(value: any, args: ValidationArguments) {
    const [format] = args.constraints;
    return typeof value === 'string' && format.test(value);
  }
}
