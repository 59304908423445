import React, { useRef, Fragment, useEffect, useContext } from 'react';
import { useGlobalState } from 'core/globalState/useGlobalState';
import { SubscriptionPaths, useSubscriptionStyles } from './SubscriptionLayout';
import Grid from '@material-ui/core/Grid';
import { WebinarFacility } from 'share/entities/webinar-facility.entity';
import { useState } from 'react';
import { MyTextField, MyRadio, yesNoOptions, MyOption, MySelectField, selectOptions, MyDualLabelTextField, CustomRadioColorLabelControl } from 'components/form/myinput';
import { useForm } from 'react-hook-form';
import { ModelList } from 'containers/model/ModelList';
import { WebinarContactView } from 'viewModel/attendeeView';
import { useHistory, Redirect } from 'react-router-dom';
import { useSteps } from 'components/hooks/useStep';
import { validateEntity } from 'core/utilities/formUtility';
import { CreateModelRequest, ResetCRUDState, UpdateModelRequest } from 'core/crud/modelActions';
import { myDispatch } from 'core/utilities/myDispatch';
import { useDispatch, useSelector } from 'react-redux';
import { selectModelUpsertState } from 'core/crud/modelSelector';
import { WebinarContact } from 'share/entities/webinar-contact.entity';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import { getEntityMeta } from 'decorators/entitydecorators';
import { useQuery } from 'components/hooks/useQuery';
import { useInputValue } from 'components/hooks/useInputValue';
import { Alert } from '@material-ui/lab';
import { Webinar } from 'share/entities/webinar.entity';
import { useLatestRequest } from 'core/crud/useLatestRequest';
import { dateToLongString,dateToLongStringTime } from 'share/utilities/myDate';
import axios from 'axios'
import { WebinarAttendee } from 'share/entities/webinar-attendee.entity';
//import { beforeCreateFacilityContact } from ''

export let defaultFacility = {
    id: '',
    new_admin_license: 'no',
    new_cap_cpe_required: 'no',
    new_city: 'nyc',
    new_nab_id_number: '2334#',
    new_name: 'Hartog Jacobs',
    new_name_on_nab: 'nt',
    new_snf: 'snf',
    new_state: 'ny',
    new_street_line: '123 main',
    new_telephone: '9998888333',
    new_zip: '2333',
    new_extension: '',
    new_street_line2: '',
    new_type: 'yearly',
    contacts: [{
        new_email: 'jescalante@hartogjacobs.com',
        new_firstname: 'fn',
        new_lastname: 'ln',
        new_parent_facility: '',
        new_title: 'ceo',
        email1: 'jescalante@hartogjacobs.com',
        new_admin_license: 'no',
        new_type_license:'100000013',
        new_cap_cpe_required: 'no',
        new_nab_id_number: '2334#',
        new_name_on_nab: 'nt'
    }
    ]
}

let emptyFacility = new WebinarFacility()
emptyFacility.contacts = [new WebinarContact()]

export function SubscriptionFacility() {
    //const 

    const lstYearlyEmail = [
        'jescalante@hartogjacobs.com'
        ,'mfurlani@hartogjacobs.com'
        ]
    const uppercased = lstYearlyEmail.map(name => name.toUpperCase());
    const repoName = getEntityMeta(WebinarFacility)?.CollectionName!

    //isolated hooks 
    const { register, handleSubmit } = useForm()
    const { nextPath } = useSteps(SubscriptionPaths)
    const dispatch = useDispatch()
    const classes = useSubscriptionStyles()
    const formRef = useRef<HTMLFormElement>(null);
    const isAdmin = useInputValue('')

    //local state
    const [errors, setErrors] = useState(new Map<string, any>())
    const [aten, setAtend] = useState<WebinarAttendee[]>([]);
    const [incr, setIncr] = useState(0);
    const [webFac, setWebFac] = useState<WebinarFacility>(new WebinarFacility())
    //global state
    const attendees = useGlobalState<WebinarContact[]>(getEntityMeta(WebinarContact)?.CollectionName!)[0]

    //other
    const [tl, setLicenseType] = useState('100000013');

    const handleChange = (event:any) => {
        setLicenseType(event.target.value);
    };
    const upsertState = useSelector(state => selectModelUpsertState(state, repoName))
    const [demo] = useQuery(['demo'])
    let item = (upsertState.item as WebinarFacility) ?? demo ? defaultFacility : emptyFacility
    const props = { register, errors, item }
    const childProps = { ...props, index: 0, arrName: 'contacts' }
    const subscriptionType = useInputValue('onetime')
    //event handler
    const triggerSubmit = () => {
        //goNextStep()
        formRef?.current?.dispatchEvent(new Event("submit"))
    }
    const onSubmit = async (model: any) => {
        (model as WebinarFacility).new_type_license = tl;
        (model as WebinarFacility).new_type = subscriptionType.value;
        const { errs, item: newItem } = await validateEntity(WebinarFacility, model)
        newItem.id = item?.id
        newItem.new_subscription_date = webinarModelState.items[0].new_start
        newItem.contacts = [...newItem.contacts, ...attendees ?? []]

        let result: any
        result = await axios.post('/api/modelEmail/new_webinarfacility',newItem)
        
        if(result.data.message)
        {
            upsertState.status = 'Fail'
            upsertState.error = result.data.message
        }else{
            setErrors(errs)
            if (isAdmin.value !== 'yes'){
                errs.delete('new_name_on_nab')
                errs.delete('new_nab_id_number')
            }
            console.log(errs)
            if (errs.size == 0) {
                const request = new CreateModelRequest({ repoName, model: newItem })
                setWebFac(newItem);
                upsertState.status = 'Succeed';
            }
        }
        

        
    }


    const showErrs = () => {
        return <Fragment>
            {upsertState.status === 'Fail' && <Alert severity='error' > {upsertState.error}</Alert>}
            {errors.size > 0 && <Alert severity='error'>Please fix the errors</Alert>}
        </Fragment>
    }
    const webinarModelState = useLatestRequest<Webinar>(getEntityMeta(Webinar)?.CollectionName!)

  
        useEffect(() => {
            if(demo==="ReminderDay")
            {
                axios
                .get('/api/model/new_webinar_attendee/?pf=test&pv=0&r=&q=all&id=0&f=&pn=0&ps=1000&so=desc&sf=createdon&k=Test')
                .then(result => {
                console.log(result.data);
                setAtend(result.data.items);
                // var element = result.data.filter((item: { new_join_url: null; }) => item.new_join_url != null);
                // console.log(element);
                })
                .catch(console.log);


            }
            if(demo==="ReminderDayBefore")
            {
                axios
                .get('/api/modelEmail/new_webinar_reminder/?pf=test&pv=0&r=&q=all&id=0&f=&pn=0&ps=1000&so=desc&sf=createdon&k=Test')
                .then(result => {
                console.log(result.data);
                setAtend([]);
                // var element = result.data.filter((item: { new_join_url: null; }) => item.new_join_url != null);
                // console.log(element);
                })
                .catch(console.log);


            }
            if(demo==="AvoidDuplicateTest")
            debugger
            {
                axios
                .get('/api/modelEmail/new_attendee_duplicate/?pf=test&pv=0&r=&q=all&id=0&f=&pn=0&ps=1000&so=desc&sf=createdon&k=Test')
                .then(result => {
                    // debugger
                console.log(result.data);
                setAtend([]);
                // var element = result.data.filter((item: { new_join_url: null; }) => item.new_join_url != null);
                // console.log(element);
                })
                .catch(console.log);
            }
        }, []);
        
        
        if(incr==0){
            if(aten.length>0){
                setIncr(1);
                 //var element = aten.filter(item => item.new_join_url != null);
                 var element = aten.filter(item => uppercased.includes(item.new_email.toUpperCase()));
                console.log(element);
                // axios
                // .get('/api/modelEmail/new_webinar_attendee/?pf=&pv=0&r=&q=all&id=0&f=&pn=0&ps=1000pv&so=desc&sf=createdon&k=')
                // .then(result => {
                //     console.log(result.data);
                // })
                // .catch(console.log);
            }
        }

        const lll = [{name:''}]
    return upsertState.status === 'Succeed' ? <Redirect to={{pathname: nextPath, state: webFac}} ></Redirect> :
        <React.Fragment>
            {upsertState.status === 'Loading' && <CircularProgress size={68} />}
            {showErrs()}
            <div>
                <Typography component="h1" variant="subtitle1" align="left">{webinarModelState?.items[0]?.new_name}</Typography>
                <Typography component="h1" variant="subtitle2" align="left">
                    { webinarModelState?.items[0]?.new_start && 
                    dateToLongStringTime(webinarModelState?.items[0]?.new_start ?? new Date())
                    } EST</Typography>
            </div>
            <div className="stepperTitles state">
                <Typography variant="h6" gutterBottom>
                    Input information
            </Typography>
                {/* <Typography component="p" align="right">
                    Pay
            </Typography> */}
            </div>

            <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                    {/* <Grid item xs={12} sm={12}>
                        <MyRadio name='new_type' onChange={subscriptionType.onChange}
                            options={[new MyOption('yearly', 'Annual Subscription'), new MyOption('onetime', 'Individual Program')]}
                            label='' {...props} />
                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                        <MyTextField name="new_firstname" label="First name*" {...childProps} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MyTextField name="new_lastname" label="Last name*" {...childProps} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MyTextField name="new_title" label="Title*" {...childProps} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MySelectField name='new_type_license' onChange={handleChange}
                            options={selectOptions} 
                            label='What, if any, license do you hold?*' {...props} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <MyRadio name='new_admin_license' onChange={isAdmin.onChange}
                            options={yesNoOptions}
                            label='If you have an Administrator license, please provide the following information*' {...props} />
                    </Grid>
                    {isAdmin.value === 'yes' && <Fragment>
                        <Grid item xs={12} sm={6}>
                            <MyDualLabelTextField name="new_name_on_nab" label="Name on file with the NAB for CEUs \n &nbsp;" {...props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MyDualLabelTextField name="new_nab_id_number" label="Please provide your NAB ID No. \n (R+7 digits) This is NOT your License No"{...props} />
                        </Grid>
                    </Fragment>
                    }
                    <Grid item xs={12} sm={12}>
                        <CustomRadioColorLabelControl name='new_cap_cpe_required' options={yesNoOptions} label='Do you need a CPA (Accountant) CPE Certificate?*' redText='(Accountant)' {...props} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MyTextField name="new_email" label="Email of Attendee*" {...childProps} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MyTextField name="email1" label="Confirm Email*" {...childProps} />
                    </Grid>
                    <Grid item xs={12}>
                        <MyTextField name="new_name" label="SNF/Organization*"  {...props} />
                    </Grid>
                    <Grid item xs={12}>
                        <MyTextField name="new_street_line" label="Address line 1*" {...props} />
                    </Grid>
                    <Grid item xs={12}>
                        <MyTextField name="new_street_line2" label="Address line 2" {...props} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MyTextField name="new_city" label="City*" {...props} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MyTextField name="new_state" label="State/Province/Region*" {...props} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MyTextField name="new_zip" label="Zip / Postal code*"  {...props} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MyTextField name="new_telephone" label="SNF/Organization Telephone*" {...props} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MyTextField name="new_extension" label="Extension" {...props} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                    </Grid>
                </Grid>
            </form>
            {subscriptionType.value === 'yearly' && <Fragment>
                <Typography variant="h6" gutterBottom>Attendees</Typography>
                <Typography variant="body1" gutterBottom>(Optional: add up to two additional registrants to your subscription)</Typography>
                <ModelList display={WebinarContactView as any} />
            </Fragment>
            }
            {showErrs()}
            <div className={classes.buttons}>
                <Button variant="contained" color="primary" onClick={triggerSubmit} className={classes.button} >Next </Button>
            </div>
        </React.Fragment>
}