import React from 'react'
import { SubscriptionLayout } from './SubscriptionLayout'
import { Route, Redirect, Switch, useRouteMatch } from 'react-router-dom'
import { SubscriptionFacility } from './SubscriptionFacility'
import { SubscriptionPay } from './SubscriptionPay'
import { SubscriptionReview } from './SubscriptionReview'
export function SubscriptionRouter() {
    const {path,url} = useRouteMatch()
    return <SubscriptionLayout>
        <Switch>
            <Route exact path={path}><Redirect to={path+'/sub0'}/></Route>
            <Route exact path={path + '/sub0'} component={SubscriptionFacility} />
            <Route exact path={path + '/sub1'} component={SubscriptionPay} />
            <Route exact path={path + '/sub2'} component={SubscriptionReview} />
        </Switch>
    </SubscriptionLayout>
}