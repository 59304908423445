function myParseNumber(s:string, format:string, key:string ) {
    const start = format.indexOf(key)
    const length = key.length
    return +s.substr(start,length)
}

export function myParseDate (str:any, format:string = 'yyyyMMddhhmmss') {
    if (!str) {
        return new Date('2020-01-01')
    }
    const s = str as string
    return new Date(myParseNumber(s, format,'yyyy'),
        myParseNumber(s,format,'MM') -1,
        myParseNumber(s,format,'dd'),
        myParseNumber(s,format,'hh'),
        myParseNumber(s,format,'mm'),
        myParseNumber(s,format,'ss')
    )
}

export function addYear(d:Date, val: number){
    const myDate = new Date(d)
    myDate.setFullYear(myDate.getFullYear() + val)
    return myDate
}

export function getToday(){
    const myDate = new Date()
    return new Date(myDate.getFullYear(), myDate.getMonth(), myDate.getDate())
}

export function addMinutes(d:Date, val:number) {
    const myDate = new Date(d)
    myDate.setMinutes(myDate.getMinutes() + val)
    return myDate
}

export const  dateToLongString = (d:Date) => myDateToString(d,'ddd, MMM dd, yyyy')

export const  dateToLongStringTime = (d:Date) => myDateToStringHourAmPm(d,'ddd, MMM dd, yyyy hh:mm t')

export function myDateToString(d :Date, format:string = 'MM/dd/yyyy') {

    const myDate = new Date(d)
    const result = format
        .replace('yyyy', myDate.getFullYear().toString())
        .replace('MMM', myDate.toLocaleString('default',{month:'long'}) )
        .replace('MM', myToString(myDate.getMonth() + 1,2))
        .replace('ddd', myDate.toLocaleString('default', {weekday:'long'}) )
        .replace('dd', myToString(myDate.getDate(),2))
        .replace('hh', myToString(myDate.getHours(),2))
        .replace('mm', myToString(myDate.getMinutes(),2))
        .replace('ss', myToString(myDate.getSeconds(),2))
    return result
}

export function myToString(n:number, length:number){
    let leadNumber = 1;
    for(let i = 0 ; i < length; i ++)
    {
        leadNumber *= 10;
    }
    return (leadNumber + n).toString().substr(1)
}

export function myDateToStringHourAmPm(d :Date, format:string = 'MM/dd/yyyy') {

    const myDate = new Date(d)
    const result = format
        .replace('yyyy', myDate.getFullYear().toString())
        .replace('MMM', myDate.toLocaleString('default',{month:'long'}) )
        .replace('MM', myToString(myDate.getMonth() + 1,2))
        .replace('ddd', myDate.toLocaleString('default', {weekday:'long'}) )
        .replace('dd', myToString(myDate.getDate(),2))
        .replace('hh', myToStringHourAmPm(myDate.getHours(),2))
        .replace('mm', myToString(myDate.getMinutes(),2))
        .replace('ss', myToString(myDate.getSeconds(),2))
        .replace(' t', tforAmPm(myDate.getSeconds(),2,'t'))
    return result
}

export function myToStringHourAmPm(n:number, length:number){
    let leadNumber = 0;
    if(n>12)
    {
        leadNumber = n-12;
    }
    if(n == 0)
        leadNumber = 12; 

    return "1"
}

export function myDateToStringHourAmPmEmail(d :Date, format:string = 'MM/dd/yyyy') {

    const myDate = new Date(d)
    const result = format
        .replace('yyyy', myDate.getFullYear().toString())
        .replace('MMM', myDate.toLocaleString('default',{month:'long'}) )
        .replace('MM', myToString(myDate.getMonth() + 1,2))
        .replace('ddd', myDate.toLocaleString('default', {weekday:'long'}) )
        .replace('dd', myToString(myDate.getDate(),2))
        .replace('hh', myToStringHourAmPm(myDate.getHours(),2))
        .replace('mm', myToString(myDate.getMinutes(),2))
        .replace('ss', myToString(myDate.getSeconds(),2))
        .replace(' t', tforAmPm(myDate.getSeconds(),2,'t'))
    return result
}

export function myToStringHourAmPmEmail(n:number, length:number){
    let leadNumber = 0;
    const GMTDynamics = 5;
    if(n>12)
    {
        leadNumber = n-12;
    }
    if(n == 0)
        leadNumber = 12; 
    console.log(leadNumber)
    console.log(leadNumber-GMTDynamics)
    return (leadNumber-GMTDynamics).toString()
}


export function tforAmPm(n:number, length:number, t:string){
    let leadNumber = 0;
    let format = ' am';
    if(n>12)
    {
        format = ' pm';
    }
    if(n == 0)
    format = ' pm'; 

    return (format).toString()
}

const monthNames = ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE",
  "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"
];

export function getFullMonth(d: Date){

    return monthNames[d.getMonth()];
}