import {applyMiddleware, createStore, compose, combineReducers} from "redux";
import { modelMiddleware } from "../crud/modelMiddleware";
import { fetchMiddleware } from "../fetch/fetchMiddleware";
import {modelReducer} from '../crud/modelReducer'
import {globalStateReducer} from '../globalState/globalStateReducer'
import {fetchReducer} from '../fetch/fetchReducer'
export const reducers = combineReducers({modelReducer, globalStateReducer,fetchReducer});
export const rootStore = createStore(
  reducers,
  compose(
    applyMiddleware(modelMiddleware,fetchMiddleware ),
  )
);