
import { useHistory } from 'react-router-dom';

export const useSteps = (paths:string[]) => {
  const history = useHistory()
  const index = paths.indexOf(history.location.pathname)
  const activeStep = index < 0 ? 0:index
  const nextPath =  activeStep === paths.length -1 ? '': paths[activeStep + 1]
  const privousPath =activeStep=== 0 ? '': paths[activeStep - 1]

  const goNextStep = () =>{
    history.push(nextPath )
  }

  const goPreviousStep = () => {
    history.push(privousPath)
  }

  return {goNextStep, goPreviousStep,activeStep, nextPath, privousPath}
}
