import React, { Fragment } from 'react';
import { EnhancedTable } from '../../components/dataTable/EnhancedTable';
import ModelEditDialog from './ModelEditDialog';
import {  MyBaseEntity} from 'share/entities/myBaseEntity';
import { ModelDisplayOption } from 'core/crud/modelDisplay';
import { useGlobalState } from 'core/globalState/useGlobalState';
import { getEntityMeta } from 'decorators/entitydecorators';

export const ModelList = ({ display}: {display:ModelDisplayOption<MyBaseEntity>}) => {
  const [items ,setItems] = useGlobalState<MyBaseEntity[]>(getEntityMeta(display.modelContructor)?.CollectionName!) 
  const [open, setOpen] = React.useState(false);
  const [editItem, setEditItem] = React.useState(null as any);
  const handleClose = () => setOpen(false)
  const handleAdd = () => {
    showDialog(null)
  }

  const handleEdit = (id: string) => {
     showDialog(items.find(x => x.id === id))
  }

  const handleDelete = (ids: string[]) => {
    if (window.confirm("do you want to delete these items?")) {
      setItems(items.filter(x=>x.id && !ids.includes(x.id)))
    }
  }
  const showDialog = (item: any) => {
    setEditItem(item)
    setOpen(true)
  }

  return (
    <Fragment>
      <ModelEditDialog item={editItem} handleClose={handleClose} display={display} open={open} />
      <EnhancedTable displayOption={{
        ...display,
        rows:items, 
        cols: display.fields.filter(x=>x.inList),
        modelTitle:display.modelTitle,
        handleAdd,
        handleDelete,
        handleEdit
        }} />
    </Fragment>
  );
}
