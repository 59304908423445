import { MyBaseEntity } from "./myBaseEntity";
import { Entity, Column,ManyToOne } from "decorators/entitydecorators";
import { IsNotEmpty, IsEmail,Matches } from "class-validator";
import { webinar_facility_schema, webinar_schema, webinar_attendee_schema } from "./entity-schema-names";

@Entity(webinar_attendee_schema,'Webinar Attendee','Webinar Attendees')
export class WebinarAttendee extends MyBaseEntity{
    @IsNotEmpty()
    @Column('First name')
    new_firstname=''
    @IsNotEmpty()
    @Column('Last name')
    new_lastname=''
    @IsNotEmpty()
    @Column('Title')
    new_title=''
    @IsEmail()
    @IsNotEmpty()
    @Column('Email',{primary:true})
    new_email=''


    @Column('Have Administrator License')
    new_admin_license =''
    @Column('What License do you have')
    new_type_license =''
    

    @Column('Name on NAB')
    new_name_on_nab= ''

    @Column('NAB ID #')
    new_nab_id_number= ''


    @Column('CPA CPE required')
    new_cap_cpe_required = ''
    
    @Column('Join URL')
    new_join_url ?= ''
    @ManyToOne(webinar_facility_schema,'Facility')
    new_parent_facility:any = ''
    @ManyToOne(webinar_schema,'Webinar')
    new_webinar?:any = ''
}

