import React, { Fragment, useEffect, useRef, useState } from "react"
import { makeStyles, Theme, FormControl, TextField } from "@material-ui/core"
import { useForm } from "react-hook-form"
import { validateEntity } from "../../core/utilities/formUtility"
import { MyBaseEntity } from "share/entities/myBaseEntity"
import { ModelDisplayOption } from "core/crud/modelDisplay"
import { useGlobalState } from "core/globalState/useGlobalState"
import { getEntityMeta } from "decorators/entitydecorators"
import { MyOption, MyRadio, yesNoOptions } from "components/form/myinput"
import { useInputValue } from "components/hooks/useInputValue"

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        margin: theme.spacing(1),
    },
})
);

export const ModelForm = ({item, display, formRef, handleClose }: { item: any, display: ModelDisplayOption<MyBaseEntity>, formRef: any , handleClose:any}) => {
    const repoName = getEntityMeta(display.modelContructor)?.CollectionName!
    const [items ,setItems] = useGlobalState<MyBaseEntity[]>(repoName) 
    const [formError, setFormError] = React.useState(new Map<string, string>());
    const { register, handleSubmit, control } = useForm()
    const classes = useStyles();
    const { modelContructor, fields } = display
    let editFields = fields.filter(x => (item ? x.inEdit : x.inAdd))
    const onSubmit = async (model: any) => {
        const error = await validateEntity(modelContructor, model, editFields.map(x => x.id))
        let exists:boolean = false
        if(items != null){
            const values = items.map( function(v) {
                return Object.values(v);
             });
             exists = values.some(x=>x.some(y=>y === model["new_email"]))
        }

        if(!exists)
        {
            setItems([...items??[],model])
            handleClose()
        }       
   }

    useEffect(() => {
        setFormError(new Map<string, string>())
    }, [item])

    const idsToCheck = 'new_admin_license';
    const idsToCheck2 = 'new_cap_cpe_required';
    const isAdmin = useInputValue('')
    const [errors, setErrors] = useState(new Map<string, any>())

    return <Fragment>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            {
                editFields.map(x => {
                    let defaultVal = item && item[x.id]
                    if(!x.id.includes(idsToCheck) && !x.id.includes(idsToCheck2)){
                    return <FormControl key={x.id}
                        style={{ width: x.fieldWidth }}
                        className={classes.formControl}>
                    
                        <TextField margin="dense" name={x.id} label={x.label} type={x.inputType} inputRef={register}
                                    helperText={formError.get(x.id)} error={formError.has(x.id)} defaultValue={defaultVal} />

                    </FormControl>
                    }
                    else
                    {
                        return <FormControl key={x.id}
                        style={{ width: x.fieldWidth }}
                        className={classes.formControl}>
                    
                        <MyRadio name={x.id} onChange={isAdmin.onChange}
                            options={yesNoOptions}
                            label={x.label} {...{ register, errors, item }} />

                        </FormControl>
                    }
                })
            }
        </form>
    </Fragment>

}