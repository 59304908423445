import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useSteps } from 'components/hooks/useStep';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useLatestRequest } from 'core/crud/useLatestRequest';
import { Webinar } from 'share/entities/webinar.entity';
import { getEntityMeta } from 'decorators/entitydecorators';
import { dateToLongString } from 'share/utilities/myDate';
const width = 800
export const useSubscriptionStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: width,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    width: width,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(width + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },

}));

export const SubscriptionPaths = ['/webinar-subscription/sub0', '/webinar-subscription/sub1', '/webinar-subscription/sub2']
export function SubscriptionLayout(props: any) {
  //const 
  //const steps = ['Input information', 'Pay', 'Review']

  //isolated hook
  const { activeStep } = useSteps(SubscriptionPaths);
  const classes = useSubscriptionStyles();



  var percentage = (activeStep + 1) * 33;

  /*
  function getCurrentStepTitle() {
    return steps[activeStep]
  }
  function getNextStepTitle() {
    return activeStep > 1 ? "" : 'Next:' + steps[activeStep + 1]
  }
  */

  //other
  const webinarModelState = useLatestRequest<Webinar>(getEntityMeta(Webinar)?.CollectionName!)

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
            <img src="https://zimmet.microsoftcrmportals.com/zimmet.jpg" style={{alignSelf: "middle"}}/>
            <Typography component="h1" variant="h5" align="center">Zimmet Healthcare Services Group, LLC </Typography>
          </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <div className="stepperTitles">
            <Typography component="h1" variant="h3" align="center">Zimmet Webinar Registration</Typography><br />
            {/* {activeStep !== 3 &&
              (
                <CircularProgressbar value={percentage} text={`${activeStep + 1}/3`} />
              )
            } */}
          </div>
          

          <React.Fragment>
            <React.Fragment>
              {props.children}
            </React.Fragment>
          </React.Fragment>
        </Paper>
      </main>
      <footer color="default">
        <Toolbar className={"space-between"}>
          <Typography component="p" color="inherit" noWrap>
            <a style={{textDecoration:"none", color:"#cccccc",fontSize:"12px"}} href="https://www.hartogjacobs.com/">© Hartog Jacobs LLC </a>
          </Typography>
          <img width='100' src={'HJLogo.png'} />
        </Toolbar>
      </footer>
    </React.Fragment>
  );
}