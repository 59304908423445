import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { QueryParamsModel } from "../../share/query/query-params"
import { GetModelRequest } from "../../core/crud/modelActions"
import { myDispatch } from "core/utilities/myDispatch"
import { selectModelLoadAllState } from '../../core/crud/modelSelector';
import { LoadAllState } from "core/crud/modelReducer"

export const useLatestRequest =<T>(repoName:string) =>{
  const dispatch = useDispatch()
  useEffect(() => {
    const query = { ...new QueryParamsModel('all'), ps:1 }
    const request = new GetModelRequest({ repoName, query })
    myDispatch(dispatch,request)
  }, [dispatch])
  const state = useSelector(state => selectModelLoadAllState(state, repoName))
  return state as LoadAllState<T>
}