import { MyBaseEntity } from "./myBaseEntity";
import { Entity, Column, ManyToOne } from "decorators/entitydecorators";
import { webinar_schema, webinar_catagory_schema } from "./entity-schema-names";

@Entity(webinar_schema,'Webinar','Webinars')
export class Webinar extends MyBaseEntity{
    @Column('Webinar',{primary:true,length:100})
    new_name:string = ''
    @Column('Start Time')
    new_start?: Date = undefined
    @Column('End Time')
    new_end?: Date = undefined
    @Column('Go to Webinar ID')
    new_gotowebinar_id?: string = undefined
    @Column('Registration URL')
    new_registeration_url?: string = undefined
    @ManyToOne(webinar_catagory_schema, 'Event Category')
    new_category?:any = undefined
}