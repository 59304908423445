import React, { Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Register } from 'containers/Registration/Register';
import { SubscriptionRouter } from 'containers/Subscription/SubscriptionRouter';
import './App.css';

export default function App() {
  return (
    <Switch>
      <Route exact path='/' ><Redirect to='/webinar-subscription'/></Route>
      <Route path='/webinar-subscription' component={SubscriptionRouter}/>
      <Route exact path='/webinar-registration' component={Register}/>
    </Switch>
  );
}