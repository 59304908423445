import { MyBaseEntity } from "./myBaseEntity";
import { Entity, Column,ManyToOne } from "decorators/entitydecorators";
import { IsNotEmpty, IsEmail,Matches } from "class-validator";
import { MyMatch } from "../decorators/mymatch.decorator";
import { webinar_contact_schema, webinar_facility_schema } from "./entity-schema-names";
import { Transform } from "class-transformer";

@Entity(webinar_contact_schema,'Webinar Contact','Webinar Contacts')
export class WebinarContact extends MyBaseEntity{

    @Matches(/^(?!\s*$).+/, { message: 'First Name should not be empty' })
    @Column('First name')
    new_firstname=''

    @Matches(/^(?!\s*$).+/, { message: 'Last Name should not be empty' })
    @Column('Last name')
    new_lastname=''

    @Matches(/^(?!\s*$).+/, { message: 'Title should not be empty' })
    @Column('Title')
    new_title=''

    @IsEmail()
    @Matches(/^(?!\s*$).+/, { message: 'Email should not be empty.' })
    @Column('Email',{primary:true})
    new_email=''

    @Column('Registration Link')
    new_registration_link=''
    // @IsNotEmpty()
    @Column('Have Administrator License')
    new_admin_license =''

    
    @Column('What License do you have')
    new_type_license =''
    // @IsNotEmpty({message:'Name on file with the NAB for CEUs is required'})
    @Column('Name on NAB')
    new_name_on_nab= ''

    // @IsNotEmpty({message:'NAB ID # (for Administrators only) is required'})
    @Column('NAB ID #')
    new_nab_id_number= ''

    // @IsNotEmpty()
    @Column('CPA CPE required')
    new_cap_cpe_required = ''
    @MyMatch('new_email',{message:'the two emails should match'})
    email1 =''
    @ManyToOne(webinar_facility_schema,'Facility')
    new_parent_facility:any = ''
    @ManyToOne('contact','Contact')
    new_contact:any = ''
}

