import React from 'react';
import { getEntityMeta } from 'decorators/entitydecorators';
import { getFeeByType, WebinarFacility } from 'share/entities/webinar-facility.entity';
import { selectModelUpsertState } from 'core/crud/modelSelector';
import { useSelector } from 'react-redux';
import { Typography, List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import { Webinar } from 'share/entities/webinar.entity';
import { dateToLongString } from 'share/utilities/myDate';
import { useLatestRequest } from 'core/crud/useLatestRequest';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },


}));
export function SubscriptionReview() {
  //const
  const repoName = getEntityMeta(WebinarFacility)?.CollectionName!
  const classes = useStyles()

  //global state
  let upsertState = useSelector(state => selectModelUpsertState(state, repoName))
  let facility = upsertState.item as WebinarFacility


  function MyListItem({ title, value }: { title: string, value: string }) {
    return <ListItem className={classes.listItem}>
      <ListItemText primary={title} />
      <Typography variant="subtitle1" className={classes.total}>
        {value}
      </Typography>
    </ListItem>
  }

  const webinarModelState = useLatestRequest<Webinar>(getEntityMeta(Webinar)?.CollectionName!)

  return (<React.Fragment>


    {facility && <React.Fragment>
      <br />

      <div>
        <Typography component="h1" variant="h6" align="left">Thank you for registering for:</Typography>
        <br />
        <Typography component="h1" variant="subtitle1" align="left">{webinarModelState?.items[0]?.new_name}</Typography>
        <Typography component="h1" variant="subtitle2" align="left">{dateToLongString(webinarModelState?.items[0]?.new_start ?? new Date())}</Typography>
      </div>
      <div className="stepperTitles state">
        <Typography variant="h6" gutterBottom>
          Review
        </Typography>
        <Typography component="p" align="right"></Typography>
      </div>

      <Typography variant="h6" gutterBottom> Payment Detail</Typography>

      <List disablePadding>
        {/* <MyListItem title="PayPal Order ID" value={facility?.new_paypal_orderid} />
        <MyListItem title="PayPal Account" value={facility?.new_paypal_email} /> */}
        <MyListItem title="Total" value={getFeeByType(facility.new_paypal_email ? facility.new_type as any : 'redeem')} />
      </List>

      <Typography variant="h6" gutterBottom> Attendee </Typography>
      <List disablePadding>
        {facility?.contacts?.map((contact) => (
          <ListItem className={classes.listItem} key={contact.new_email}>
            <ListItemText primary={`${contact.new_firstname} ${contact.new_lastname} `} secondary={contact.new_title} />
            <Typography variant="body2">{contact.new_email}</Typography>
          </ListItem>
        ))}
      </List>

      <Typography variant="h6" gutterBottom> Facility Detail </Typography>
      <Typography component="h1" variant="subtitle2" align="left">If any of this information needs to be revised, please contact Zimmet at info@zhealthcare.com to update.</Typography>

      <List disablePadding>
        <MyListItem title="Do you have an Administrator license?" value={facility?.new_admin_license} />
        <MyListItem title="Name on file with the NAB for CEUs" value={facility?.new_name_on_nab} />
        <MyListItem title="NAB ID# (for Administrators only)" value={facility?.new_nab_id_number} />
        <MyListItem title="CPA CPE Certificate Required" value={facility?.new_cap_cpe_required} />
        <MyListItem title="SNF/Organization" value={facility?.new_name} />
        <MyListItem title="SNF/Organization Address" value={facility?.new_street_line} />
        <MyListItem title="Address Line 2" value={facility?.new_street_line2} />
        <MyListItem title="City" value={facility?.new_city} />
        <MyListItem title="State / Province / Region" value={facility?.new_state} />
        <MyListItem title="SNF/Organization Telephone" value={facility?.new_telephone} />
        <MyListItem title="Extension" value={facility?.new_extension} />


      </List>
      <Typography component="h1" variant="subtitle2" align="left">Within the next 15 minutes, a confirmation will be sent to the email address you registered with.  Please be sure to check your junk and spam mail. If you have any questions, please contact info@zhealthcare.com.Thank you.</Typography>
    </React.Fragment>
    }
  </React.Fragment>
  );
}