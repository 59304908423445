import {ListItem, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, ListItemText, Typography, Select, MenuItem } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import  React  from 'react';

interface MyInputOption{
    name:string
    label:string
    register:any
    errors:Map<string,any>
    item?:any
    onChange?:any
    arrName?:string
    index?:number
    options?:{value:string,label:string}[]
}

export function MyTextField({name,label,register,errors,arrName,index,item}: MyInputOption){
    let fullname = name
    if (arrName!=undefined && index != undefined){
        const arrError = errors?.get(arrName)
        if (arrError && arrError.get){
            errors = arrError.get(`${index}`)
        }
        fullname = `${arrName}[${index}].${name}`
        item = item[arrName][index]
    }
    const defaultValue = item? item[name]:''
    return <TextField  name={fullname} {...{label,defaultValue}} fullWidth inputRef={register} 
        helperText={errors?.get(name)?.replace(name,label.replace('*',''))} error={errors?.has(name) }/>
}

export function MyRadio({ errors, onChange, name, label, register,options,item }: MyInputOption) {
    const defaultValue = item? item[name]:''
    return <FormControl component="fieldset">
        <FormLabel >{label} </FormLabel>
        {errors.get(name) && <Alert severity='error'>Please select one option</Alert>}
        <RadioGroup aria-label="gender" name="new_admin_license" onChange={onChange} defaultValue={defaultValue}>{
                options?.map(x=><FormControlLabel key={x.value} {...x} control={<Radio inputRef={register} name={name} />} />)
            }
        </RadioGroup>
    </FormControl>
}

export function CustomRadioColorLabelControl({ errors, onChange, name, label, register, options, item,redText }: MyInputOption & { redText?: string }) {
  const parts = redText ? label.split(new RegExp(`\\(${redText}\\)`, 'g')) : [label];
  const defaultValue = item? item[name]:''
  console.log(redText)
  console.log(parts);
  return (
    <FormControl component="fieldset">
      <FormLabel>
        {parts.map((part, index) => (
          <React.Fragment key={index}>
            {index === 0 && part}
            {index === 1 && redText && <span style={{ color: 'red' }}>{`${redText}`}</span>}
            {index === 2 && part}
          </React.Fragment>
        ))}
      </FormLabel>
      {errors.get(name) && <Alert severity='error'>Please select one option</Alert>}
      <RadioGroup aria-label="options" name={name} onChange={onChange} defaultValue={defaultValue}>
        {options?.map((x) => (
          <FormControlLabel key={x.value} {...x} control={<Radio inputRef={register} name={name} />} />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

 export function MySelectField({ errors, onChange, name, label, register,options,item }: MyInputOption)
 {
  const formControlStyle = {
    border: '2px solid #EDEDED', 
    borderRadius: '4px', 
    padding: '3px',
    marginTop: '5px'
  };
   
    return <FormControl component="fieldset" >
        <FormLabel >{label} </FormLabel>
        {errors.get(name) && <Alert severity='error'>Please select one option</Alert>}
        <Select style={formControlStyle} name="new_type_license" onChange={onChange} >{
                options?.map(x=><MenuItem key={x.value} value={x.value}>{x.label}</MenuItem>)
            }
        </Select>
    </FormControl>
 }

 export function MyDualLabelTextField({ name, label, register, errors, arrName, index, item }: MyInputOption)
  {
    let fullname = name;

    if (arrName !== undefined && index !== undefined) {
      const arrError = errors?.get(arrName);
  
      if (arrError && arrError.get) {
        errors = arrError.get(`${index}`);
      }
  
      fullname = `${arrName}[${index}].${name}`;
      item = item[arrName][index];
    }
  
    const defaultValue = item ? item[name] : '';
  
    const labelLines = label.split('\\n');
    const firstLine = labelLines[0];
    const secondLine = labelLines[1];
  
    return (
      <div>
        <label>
          {firstLine}
          <br />
          <span style={{ color: 'red' }}>{secondLine}</span>
        </label>
        <TextField
          name={fullname}
         
          defaultValue={defaultValue}
          fullWidth
          inputRef={register}
          helperText={errors?.get(name)?.replace(name, firstLine.replace('*', ''))}
          error={errors?.has(name)}
        />
      </div>
    );
  }
  
  

export class MyOption {
    constructor(
        public value:string, 
        public label:string){ }
}
export const yesNoOptions = [
    new MyOption ('yes', 'Yes'),
    new MyOption ('no', 'No'),
]

export const selectOptions = [
    new MyOption ('100000000','CNA'),
    new MyOption ('100000001','CPA'),
    new MyOption ('100000002','CRT'),
    new MyOption ('100000003','LCSW'),
    new MyOption ('100000004','LNHA'),
    new MyOption ('100000005','LPN'),
    new MyOption ('100000006','OT'),
    new MyOption ('100000007','PT'),
    new MyOption ('100000008','RD'),
    new MyOption ('100000009','RDN'),
    new MyOption ('100000010','RN'),
    new MyOption ('100000011','RRT'),
    new MyOption ('100000012','SP'),
    new MyOption ('100000013','Not Applicable'),
]