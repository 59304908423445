import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { QueryParamsModel } from "../../share/query/query-params"
import { GetModelRequest, ResetCRUDState } from "../../core/crud/modelActions"
import { selectModelLoadOneState } from "../../core/crud/modelSelector"
import { myDispatch } from "core/utilities/myDispatch"

export const useClearCRUDState =(repoName:string) =>{
  const dispatch = useDispatch()
  useEffect(() => {
    myDispatch(dispatch, new ResetCRUDState({repoName}))
  }, [repoName])
}