import React, { Fragment, useEffect, useState } from 'react';
import { SubscriptionPaths } from './SubscriptionLayout';
import { PayPalButton } from "react-paypal-button-v2";
import { getFeeByType, WebinarFacility } from 'share/entities/webinar-facility.entity';
import { Typography, List, ListItem, ListItemText, makeStyles, Button, CircularProgress, Checkbox, FormControlLabel } from '@material-ui/core';
import { Redirect, useLocation  } from 'react-router-dom';
import { useSteps } from 'components/hooks/useStep';
import { selectModelUpsertState } from 'core/crud/modelSelector';
import { useSelector, useDispatch } from 'react-redux';
import { myDispatch } from 'core/utilities/myDispatch';
import { UpdateModelRequest, CreateModelRequest } from 'core/crud/modelActions';
import { getEntityMeta } from 'decorators/entitydecorators';
import { useClearCRUDState } from 'core/crud/useClearCRUDState';
import { dateToLongString } from 'share/utilities/myDate';
import { Webinar } from 'share/entities/webinar.entity';
import { useLatestRequest } from 'core/crud/useLatestRequest';
import { Alert } from '@material-ui/lab';
import { validateEntity } from 'core/utilities/formUtility';
import { useInputValue } from 'components/hooks/useInputValue';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },


}));

export function SubscriptionPay() {
  
  //const
  const repoName = getEntityMeta(WebinarFacility)?.CollectionName!
  const { nextPath, goPreviousStep, goNextStep } = useSteps(SubscriptionPaths)
  const [errors, setErrors] = useState(new Map<string, any>())

  //isolated hook
  const classes = useStyles()
  const dispatch = useDispatch()
  useClearCRUDState(repoName)
  const isAdmin = useInputValue('')
  
  //local state
  const [paid, setpaid] = useState(false)

  //global state
  let upsertState = useSelector(state => selectModelUpsertState(state, repoName))
  const  data  = useLocation().state;
  let facility = data as WebinarFacility //upsertState.item as WebinarFacility

  useEffect(() => {
    handlePay();
}, []);

  //event handler
  const handlePay = async (details: {
    name: { given_name: string, surname: string },
    payer: { email_address: string }
  } | undefined = undefined,
    data: { orderID: string } | undefined = undefined) => {
    setpaid(true)
    var isPaid = 'yes';

      facility.new_paid= isPaid
      facility.new_paypal_email= details?.payer?.email_address ?? ''
      facility.new_paypal_orderid= data?.orderID ?? ''
      facility.contacts[0].new_admin_license = facility.new_admin_license
      facility.contacts[0].new_type_license = facility.new_type_license
      facility.contacts[0].new_cap_cpe_required = facility.new_cap_cpe_required
      facility.contacts[0].new_nab_id_number = facility.new_nab_id_number
      facility.contacts[0].new_name_on_nab = facility.new_name_on_nab
      const { errs, item: newItem } = await validateEntity(WebinarFacility, facility)
      setErrors(errs)
      if (isAdmin.value !== 'yes'){
        errs.delete('new_name_on_nab')
        errs.delete('new_nab_id_number')
    }
      if (errs.size == 0) {
        const request = facility.id ? new UpdateModelRequest({ repoName, model: facility })
                    : new CreateModelRequest({ repoName, model: facility })
        myDispatch(dispatch, request)
      }

  }

   const handlePayCheckButton = async (details: {
    name: { given_name: string, surname: string },
    payer: { email_address: string }
  } | undefined = undefined,
    data: { orderID: string } | undefined = undefined) => {
    setpaid(true)
    var isPaid = 'Complimentary';

    facility.new_paid= isPaid
    facility.new_paypal_email= details?.payer?.email_address ?? ''
    facility.new_paypal_orderid= data?.orderID ?? ''
    facility.contacts[0].new_admin_license = facility.new_admin_license
    facility.contacts[0].new_cap_cpe_required = facility.new_cap_cpe_required
    facility.contacts[0].new_nab_id_number = facility.new_nab_id_number
    facility.contacts[0].new_name_on_nab = facility.new_name_on_nab

      const request = facility.id ? new UpdateModelRequest({ repoName, model: facility })
                  : new CreateModelRequest({ repoName, model: facility })
      myDispatch(dispatch, request)

  }

  const webinarModelState = useLatestRequest<Webinar>(getEntityMeta(Webinar)?.CollectionName!)

  const getFee = (facility: WebinarFacility) => getFeeByType(facility.new_type as any)
    const showErrs = () => {
        return <Fragment>
            {upsertState.status === 'Fail' && <Alert severity='error' > {upsertState.error}</Alert>}
        </Fragment>
    }
    
  return upsertState.status === 'Succeed' && paid ? <Redirect to={nextPath} ></Redirect>
    : <Fragment>
      {upsertState.status === 'Loading' && <CircularProgress size={68} />}
      {showErrs()}
      <div>
        <Typography component="h1" variant="subtitle1" align="left">{webinarModelState?.items[0]?.new_name}</Typography>
        <Typography component="h1" variant="subtitle2" align="left">{dateToLongString(webinarModelState?.items[0]?.new_start ?? new Date())}</Typography>
      </div>
      {/* <div className="stepperTitles state">
        <Typography variant="h6" gutterBottom>
          Pay
        </Typography>
        <Typography component="p" align="right">
          Review
            </Typography>
      </div> */}

      {/* <List disablePadding>
        <ListItem className={classes.listItem} >
          <ListItemText primary={facility?.new_name} />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" className={classes.total}>{getFee(facility)} </Typography>
        </ListItem>
      </List>
      <PayPalButton amount={getFee(facility)}
        shippingPreference="NO_SHIPPING"
        onSuccess={handlePay}
        options={{
          clientId: "AYEyrdc9WnQSP0BXZL3uwtCpjqC6xhZIvXYYfkhCyg6e7gRo4NP8oHPc2H1est8OtVYncUTACPpseT-z"
          //clientId: "sb"
        }}
      />
      <FormControlLabel
        control={<Checkbox onChange={() => handlePayCheckButton()} name="checkedA" />}
        label="My Facility previously subscribed. No payment is required. If unsure of prepaid subscription status, please email us at info@zhealthcare.com to confirm."
      /> */}

      {/* {false && <div className={classes.buttons}>
        <Button onClick={goPreviousStep} className={classes.button} > Back </Button>
      </div>
      } */}
    </Fragment>
}